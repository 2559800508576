import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppHeader,
} from '@coreui/react';
// routes config
import routes from '../../routes';
import DefaultHeader from './DefaultHeader';

class DefaultLayout extends Component {
  render() {

    const {history} = this.props;
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader history={history}/>
        </AppHeader>
        <div className="app-body">
          <main className="main">
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />)
                      : (null);
                  },
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Container>
          </main>
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
