import React, { Component } from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand } from '@coreui/react';
import logo from '../../assets/img/brand/logo.svg'
import sygnet from '../../assets/img/brand/sygnet.svg'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};



class DefaultHeader extends Component {
  changePage(e, history, page) {
    e.preventDefault();

    history.push(page)
  }

  render() {

    // eslint-disable-next-line
    const { history} = this.props;

    return (
      <React.Fragment>
        <AppNavbarBrand
          full={{ src: logo, width: 89, height: 25, alt: 'CoreUI Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />

        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="/" onClick={(e) => this.changePage(e, history, '/')}>Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/profile" onClick={(e) => this.changePage(e, history, '/profile')}>Profile</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/login" onClick={(e) => this.changePage(e, history, '/login')}>Logout</NavLink>
          </NavItem>

        </Nav>
        <Nav className="ml-auto d-md-none" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <i className="icon-menu icons font-2xl"></i>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem onClick={() => history.push('/') }>Dashboard</DropdownItem>
              <DropdownItem onClick={() => history.push('/profile') }>Profile</DropdownItem>
              <DropdownItem onClick={() => history.push('/login') }>Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
